<template>
  <div>
    <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8">
    </base-header>
    <div class="container-fluid mt--7">
      <div class="row">
        <div class="col">
          <div class="card shadow" :class="type === 'dark' ? 'bg-default' : ''">
            <div
              class="card-header border-0"
              :class="type === 'dark' ? 'bg-transparent' : ''"
            >
              <div class="row align-items-center">
                <div class="col">
                  <h3 class="mb-0" :class="type === 'dark' ? 'text-white' : ''">
                    Approved product key batches
                  </h3>
                </div>
              </div>
            </div>

            <div class="table-responsive">
              <el-table
                empty-text="No approved batch"
                v-loading="loading"
                style="width: 100%"
                class="table align-items-center table-flush"
                :class="type === 'dark' ? 'table-dark' : ''"
                :data="approvedProductKeyBatches"
                :default-sort="{ prop: 'createdAt', order: 'descending' }"
              >
                <el-table-column sortable prop="name" label="Name">
                  <template #default="scope">
                    <span class="text-sm">{{ scope.row.name }}</span>
                  </template>
                </el-table-column>
                <el-table-column sortable prop="productName" label="Product">
                  <template #default="scope">
                    <span class="text-sm">{{ scope.row.product.name }}</span>
                  </template>
                </el-table-column>
                <el-table-column sortable prop="companyName" label="Company">
                  <template #default="scope">
                    <span class="text-sm">{{ scope.row.company.name }}</span>
                  </template>
                </el-table-column>
                <el-table-column sortable prop="status" label="Status">
                  <template #default="scope">
                    <el-tag type="primary">{{ scope.row.status }}</el-tag>
                  </template>
                </el-table-column>
                <el-table-column sortable prop="count" label="Total">
                  <template #default="scope">
                    {{ scope.row.count }}
                  </template>
                </el-table-column>
                <el-table-column sortable prop="used" label="Used">
                  <template #default="scope">
                    {{ scope.row.used }}
                  </template>
                </el-table-column>
                <el-table-column sortable prop="points" label="Points">
                  <template #default="scope">
                    {{ scope.row.points }}
                  </template>
                </el-table-column>
                <el-table-column width="240" label="Additional Information">
                  <template #default="scope">
                    {{ scope.row.additionalInformation }}
                  </template>
                </el-table-column>
                <el-table-column
                  prop="approvedAt"
                  sortable
                  label="Approved Date & Time"
                >
                  <template #default="scope">
                    {{ moment(scope.row.updatedAt) }}
                  </template>
                </el-table-column>
                <el-table-column>
                  <template #default="scope">
                    <el-popover
                      placement="top-start"
                      :width="240"
                      trigger="click"
                    >
                      <a
                        class="dropdown-item hover"
                        @click="goToProductQRBatchDetails(scope.row)"
                      >
                        Go to QR Batch details
                      </a>
                      <template #reference>
                        <i class="fas fa-ellipsis-v hover"></i>
                      </template>
                    </el-popover>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import moment from "moment";

export default {
  name: "requested-product-key-batches",
  computed: {
    approvedProductKeyBatches() {
      return this.$store.state.admin.approvedProductKeyBatches;
    },
    moment() {
      return (date) => {
        return moment(date).format("MMMM Do YYYY, h:mm:ss a");
      };
    },
  },
  props: {
    type: String,
    title: String,
  },
  mounted() {
    this.refreshApprovedProductKeyBatches();
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    refreshApprovedProductKeyBatches() {
      this.loading = true;
      this.$store.dispatch("getApprovedProductKeyBatches").finally(() => {
        this.loading = false;
      });
    },
    goToProductQRBatchDetails(qrBatch) {
      this.$router.push({
        name: "CompanyProductQRBatchDetails",
        params: {
          companyId: qrBatch.companyId,
          productId: qrBatch.productId,
          qrBatchId: qrBatch.id,
        },
      });
    },
  },
};
</script>
<style></style>

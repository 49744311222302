<template>
  <div class="card shadow" :class="type === 'dark' ? 'bg-default' : ''">
    <div
      class="card-header border-0"
      :class="type === 'dark' ? 'bg-transparent' : ''"
    >
      <div class="row align-items-center">
        <div class="col">
          <h3 class="mb-0" :class="type === 'dark' ? 'text-white' : ''">
            {{ title }}
          </h3>
        </div>
        <div class="col text-right" @click="this.addNewCompanyUser">
          <base-button type="primary" size="sm"
            >Add new company user
          </base-button>
        </div>
      </div>
    </div>

    <div class="table-responsive">
      <el-table
        empty-text="There is no user yet"
        v-loading="loading"
        style="width: 100%"
        class="table align-items-center table-flush"
        :class="type === 'dark' ? 'table-dark' : ''"
        :data="companyUsers"
      >
        <el-table-column
          sortable
          prop="firstName"
          label="First Name"
          width="160"
        >
          <template #default="scope">
            <span class="text-sm">{{ scope.row.firstName }}</span>
          </template>
        </el-table-column>
        <el-table-column sortable prop="lastName" label="Last Name" width="160">
          <template #default="scope">
            {{ scope.row.lastName }}
          </template>
        </el-table-column>
        <el-table-column sortable prop="username" label="Username" width="160">
          <template #default="scope">
            {{ scope.row.username }}
          </template>
        </el-table-column>
        <el-table-column sortable prop="email" label="Email" width="220">
          <template #default="scope">
            {{ scope.row.email }}
          </template>
        </el-table-column>
        <el-table-column
          sortable
          prop="phoneNumber"
          label="Phone Number"
          width="180"
        >
          <template #default="scope">
            {{ scope.row.phoneNumber }}
          </template>
        </el-table-column>
        <el-table-column sortable prop="role" label="Role" width="140">
          <template #default="scope">
            {{ scope.row.role }}
          </template>
        </el-table-column>
        <el-table-column sortable prop="status" label="Status" width="140">
          <template #default="scope">
            {{ scope.row.status }}
          </template>
        </el-table-column>
        <el-table-column label="Introduction" width="180">
          <template #default="scope">
            {{ scope.row.introduction }}
          </template>
        </el-table-column>
        <el-table-column>
          <template #default="scope">
            <el-popover placement="top-start" :width="240" trigger="click">
              <a
                class="dropdown-item hover"
                @click="goToCompanyUserDetails(scope.row)"
              >
                Go to company user details
              </a>
              <template #reference>
                <i class="fas fa-ellipsis-v hover"></i>
              </template>
            </el-popover>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>
<script>
export default {
  name: "company-users-table",
  data() {
    return {
      loading: true,
    };
  },
  computed: {
    companyUsers() {
      return this.$store.state.admin.companyUsers;
    },
  },
  props: {
    type: String,
    title: String,
    companyId: {
      type: String,
      required: true,
    },
  },
  mounted() {
    if (this.companyId === undefined || this.companyId === null) {
      this.$router.back();
    } else {
      this.loading = true;
      this.$store
        .dispatch("getCompanyUsers", this.companyId)
        .then((response) => {
          if (response) {
            this.loading = false;
          }
        });
    }
  },
  methods: {
    addNewCompanyUser() {
      this.$router.push({
        name: "NewCompanyUser",
        params: { companyId: this.companyId },
      });
    },
    goToCompanyUserDetails(company) {
      this.$router.push({
        name: "company-user-details",
        params: { company: company, title: `${company.name}` },
      });
    },
  },
};
</script>
<style></style>

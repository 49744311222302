import axios from "axios";

const Admin = axios.create({
  baseURL: process.env.VUE_APP_SERVER_URL,
  headers: {
    name: "admin",
  },
});

export { Admin };

<template>
  <div>
    <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8">
      <!-- Card stats -->
      <div class="row">
        <div class="col-lg-12">
          <div class="card card-stats">
            <!-- Card body -->
            <div class="card-body" v-if="batchDetails">
              <div class="row">
                <div class="col">
                  <h5 class="card-title text-uppercase text-muted mb-0">
                    Batch Details
                  </h5>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <p class="mt-3 mb-0 text-sm">
                    <span class="text-success mr-2">Name:</span>
                    <span class="text-nowrap">{{ batchDetails.name }}</span>
                  </p>
                  <p class="mt-1 mb-0 text-sm">
                    <span class="text-success mr-2">Status:</span>
                    <span class="text-nowrap">{{ batchDetails.status }}</span>
                  </p>
                  <p class="mt-1 mb-0 text-sm">
                    <span class="text-success mr-2">Total Number of Keys:</span>
                    <span class="text-nowrap">{{ batchDetails.count }}</span>
                  </p>
                </div>
                <div class="col">
                  <p class="mt-1 mb-0 text-sm">
                    <span class="text-success mr-2">Number of Keys Used:</span>
                    <span class="text-nowrap">{{ batchDetails.used }}</span>
                  </p>
                  <p class="mt-1 mb-0 text-sm">
                    <span class="text-success mr-2">Points:</span>
                    <span class="text-nowrap">{{ batchDetails.points }}</span>
                  </p>
                  <p class="mt-1 mb-0 text-sm">
                    <span class="text-success mr-2"
                      >Additional Information:</span
                    >
                    <span class="text-nowrap">{{
                      batchDetails.additionalInformation
                    }}</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </base-header>

    <div class="container-fluid mt--7">
      <div class="row">
        <div class="col">
          <company-product-qr-batch-keys-table
            type="dark"
            title="QR Batch Keys"
            :companyId="companyId"
            :productId="productId"
            :qrBatchId="qrBatchId"
          ></company-product-qr-batch-keys-table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import CompanyProductQRBatchKeysTable from "./Tables/CompanyProductQRBatchKeysTable";
import { GetCompanyProductQRBatchDetailsProxy } from "@/proxies/GetCompanyProductQRBatchDetailsProxy";

export default {
  name: "company-product-qr-batch-details",
  components: {
    "company-product-qr-batch-keys-table": CompanyProductQRBatchKeysTable,
  },
  props: {
    companyId: {
      type: String,
      required: true,
    },
    productId: {
      type: String,
      required: true,
    },
    qrBatchId: {
      type: String,
      required: true,
    },
  },
  created() {
    this.getCompanyProductDetail();
  },
  data() {
    return {
      batchDetails: null,
    };
  },
  methods: {
    getCompanyProductDetail() {
      new GetCompanyProductQRBatchDetailsProxy({
        item: {
          companyId: this.companyId,
          productId: this.productId,
          productKeyBatchId: this.qrBatchId,
        },
      })
        .all()
        .then((response) => {
          this.batchDetails = response;
        });
    },
  },
};
</script>
<style></style>

import { createStore } from "vuex";
import admin from "./modules/admin/index";

const store = createStore({
  modules: {
    admin: admin,
  },
  state() {
    return {};
  },
  getters: {},
  mutations: {},
  actions: {},
});

export default store;

import { BaseProxy } from "./BaseProxy";

export class RequestCompanyProductQRBatchProxy extends BaseProxy {
  constructor({ item, parameters = {} } = {}) {
    super({
      endpoint: `companies/${item.companyId}/products/${item.productId}/key-batches/request/`,
      parameters,
    });
  }
}

export default {
  companies: [],
  companyProducts: [],
  companyUsers: [],
  createCompanyUserException: null,
  companyProductQRBatches: [],
  refreshCompanyProductQRBatches: false,
  companyProductQRBatchKeys: [],
  approvedProductKeyBatches: [],
  requestedProductKeyBatches: [],
};

<template>
  <div class="card">
    <div class="card-header border-0">
      <div class="row align-items-center">
        <div class="col">
          <h3 class="mb-0">Page visits</h3>
        </div>
        <div class="col text-right">
          <a href="#!" class="btn btn-sm btn-primary">See all</a>
        </div>
      </div>
    </div>

    <div class="table-responsive">
      <el-table
        v-loading="tableData.length === 0"
        style="width: 100%"
        class="table align-items-center table-flush"
        :data="tableData"
      >
        <el-table-column label="Page name" width="200">
          <template #default="scope">
            <span class="text-sm">{{ scope.row.page }}</span>
          </template>
        </el-table-column>
        <el-table-column label="Visitors" width="180">
          <template #default="scope">
            {{ scope.row.visitors }}
          </template>
        </el-table-column>
        <el-table-column label="Unique users" width="180">
          <template #default="scope">
            {{ scope.row.unique }}
          </template>
        </el-table-column>
        <el-table-column label="Bounce rate">
          <template #default="scope">
            <i
              class="fas fa-arrow-up text-success mr-3"
              :class="
                scope.row.bounceRateDirection === 'up'
                  ? 'text-success'
                  : 'text-danger'
              "
            >
            </i>
            {{ scope.row.bounceRate }}
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>
<script>
export default {
  name: "page-visits-table",
  data() {
    return {
      tableData: [
        {
          page: "/argon/",
          visitors: "4,569",
          unique: "340",
          bounceRate: "46,53%",
          bounceRateDirection: "up",
        },
        {
          page: "/argon/index.html",
          visitors: "3,985",
          unique: "319",
          bounceRate: "46,53%",
          bounceRateDirection: "down",
        },
        {
          page: "/argon/charts.html",
          visitors: "3,513",
          unique: "294",
          bounceRate: "36,49%",
          bounceRateDirection: "down",
        },
        {
          page: "/argon/tables.html",
          visitors: "2,050",
          unique: "147",
          bounceRate: "50,87%",
          bounceRateDirection: "up",
        },
        {
          page: "/argon/profile.html",
          visitors: "1,795",
          unique: "190",
          bounceRate: "46,53%",
          bounceRateDirection: "down",
        },
      ],
    };
  },
};
</script>
<style></style>

<template>
  <div>
    <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8">
    </base-header>

    <div class="container-fluid mt--7 col-lg-5 col-md-7">
      <div class="card bg-secondary shadow border-0">
        <div class="card-header bg-transparent pb-3">
          <div class="text-muted text-center mt-2 mb-3">
            <small>CREATE NEW COMPANY USER</small>
          </div>
        </div>
        <el-tooltip
          class="history-back-btn"
          content="Go back to company details"
          placement="top"
        >
          <el-button @click="goBack">
            <i class="el-icon-close" />
          </el-button>
        </el-tooltip>
        <div class="card-body px-lg-5 py-lg-5">
          <Form as="el-form" :validation-schema="schema" @submit="onSubmit">
            <BasicInput
              v-model="companyUser.firstName"
              :leftIcon="'el-icon-user-solid'"
              label="First Name"
              name="firstName"
            />
            <BasicInput
              v-model="companyUser.lastName"
              :leftIcon="'el-icon-user-solid'"
              label="Last Name"
              name="lastName"
            />
            <BasicInput
              v-model="companyUser.userName"
              :leftIcon="'el-icon-s-custom'"
              label="User Name"
              name="userName"
            />
            <BasicInput
              v-model="companyUser.password"
              :leftIcon="'el-icon-key'"
              label="Password"
              name="password"
            />
            <BasicInput
              v-model="companyUser.email"
              :leftIcon="'el-icon-message'"
              label="Email"
              name="email"
            />
            <BasicInput
              v-model="companyUser.phoneNumber"
              :leftIcon="'el-icon-phone'"
              label="Phone Number"
              name="phoneNumber"
            />
            <BasicSelect
              v-model="companyUser.role"
              :leftIcon="'el-icon-lock'"
              :options="roles"
              label="Roles"
              name="roles"
            />
            <BasicInput
              v-model="companyUser.introduction"
              :leftIcon="'el-icon-info'"
              label="Introduction"
              name="introduction"
            />
            <div>
              <base-button
                style="width: 100%"
                type="primary"
                native-type="submit"
                class="my-4"
              >
                Submit
              </base-button>
            </div>
          </Form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Form } from "vee-validate";
import * as yup from "yup";
import BasicInput from "../components/FormElements/Input/BasicInput";
import BasicSelect from "@/components/FormElements/Select/BasicSelect";
import { errorMessagesMap } from "@/common/errorMessages";
export default {
  name: "CreateCompanyUser",
  props: {
    companyId: {
      type: String,
      required: true,
    },
  },
  components: {
    Form,
    BasicInput,
    BasicSelect,
  },
  data() {
    return {
      schema: {
        email: yup
          .string()
          .required()
          .email("Please enter valid email")
          .label("Email address"),
        roles: yup.string().required().label("Roles"),
        firstName: yup.string().required().label("First Name"),
        lastName: yup.string().required().label("Last Name"),
        userName: yup
          .string()
          .required()
          .min(7, "User name should be between 7-32 character")
          .max(32, "User name should be between 7-32 character")
          .matches(/^\S+$/, "Spaces are not allowed")
          .label("User Name"),
        password: yup
          .string()
          .required()
          .min(6, "Password should be minimum 6 characters")
          .label("Password"),
        introduction: yup.string(),
        phoneNumber: yup
          .string()
          .matches(
            /^[+]?(\d{1,2})?[\s.-]?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
            "Please enter valid phone number"
          )
          .required()
          .label("Phone Number"),
      },
      roles: [
        {
          value: "ADMIN",
          label: "Admin",
        },
        {
          value: "EDITOR",
          label: "Editor",
        },
      ],
      companyUser: {
        companyId: this.companyId,
        firstName: "",
        lastName: "",
        userName: "",
        password: "",
        email: "",
        phoneNumber: "",
        role: "",
        avatar: "",
        introduction: "",
      },
    };
  },
  methods: {
    onSubmit() {
      this.$store
        .dispatch("createCompanyUser", {
          companyId: this.companyUser.companyId,
          email: this.companyUser.email,
          firstName: this.companyUser.firstName,
          introduction: this.companyUser.introduction,
          lastName: this.companyUser.lastName,
          password: this.companyUser.password,
          phoneNumber: this.companyUser.phoneNumber,
          role: this.companyUser.role,
          username: this.companyUser.userName,
        })
        .then((response) => {
          if (response) {
            this.$router.push({
              name: "CompanyDetails",
              params: { companyId: this.companyId },
            });
          }
        })
        .catch((error) => {
          this.$notify({
            text: errorMessagesMap.get(error.message),
            type: "error",
            duration: 10000,
          });
        });
    },
    goBack() {
      this.$router.push({
        name: "CompanyDetails",
        params: { companyId: this.companyId },
      });
    },
  },
};
</script>
<style></style>

import { BaseProxy } from "./BaseProxy";

export class GetCompanyDetails extends BaseProxy {
  constructor({ companyId, parameters = {} } = {}) {
    super({
      endpoint: `companies/${companyId}/`,
      parameters,
    });
  }
}

import { BaseProxy } from "./BaseProxy";

export class CreateCompanyProxy extends BaseProxy {
  constructor({ parameters = {} } = {}) {
    super({
      endpoint: "companies/",
      parameters,
    });
  }
}

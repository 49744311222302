import AuthLayout from "@/layout/AuthLayout";

import DashboardLayout from "@/layout/DashboardLayout";
import { createRouter, createWebHashHistory } from "vue-router";
import Companies from "../views/Companies.vue";
import CompanyDetails from "../views/CompanyDetails.vue";
import CompanyProductDetails from "../views/CompanyProductDetails.vue";
import CompanyProductQRBatchDetails from "../views/CompanyProductQRBatchDetails.vue";
import CreateCompany from "../views/CreateCompany.vue";
import CreateCompanyProduct from "../views/CreateCompanyProduct.vue";
import CreateCompanyUser from "../views/CreateCompanyUser";
import Dashboard from "../views/Dashboard.vue";
import Icons from "../views/Icons.vue";
import Login from "../views/Login.vue";
import Maps from "../views/Maps.vue";
import ApprovedProductKeyBatches from "../views/ProductKeyBatches/ApprovedProductKeyBatches";
import RequestedProductKeyBatches from "../views/ProductKeyBatches/RequestedProductKeyBatches.vue";
import RequestCompanyProductQRBatch from "../views/RequestCompanyProductQRBatch.vue";
import Profile from "../views/UserProfile.vue";

const routes = [
  {
    path: "/",
    redirect: "/login",
    component: AuthLayout,
    children: [
      {
        path: "/login",
        name: "login",
        components: { default: Login },
      },
    ],
  },
  {
    path: "/",
    redirect: "/dashboard",
    component: DashboardLayout,
    children: [
      {
        path: "/dashboard",
        name: "dashboard",
        components: { default: Dashboard },
      },
      {
        path: "/icons",
        name: "icons",
        components: { default: Icons },
      },
      {
        path: "/maps",
        name: "maps",
        components: { default: Maps },
      },
      {
        path: "/profile",
        name: "profile",
        components: { default: Profile },
      },
      {
        path: "/companies",
        name: "Companies",
        components: { default: Companies },
      },
      {
        path: "/companies/new",
        name: "NewCompany",
        components: { default: CreateCompany },
      },
      {
        path: "/companies/:companyId",
        name: "CompanyDetails",
        components: { default: CompanyDetails },
        props: true,
      },
      {
        path: "/companies/:companyId/products/new",
        name: "NewCompanyProduct",
        components: { default: CreateCompanyProduct },
        props: true,
      },
      {
        path: "/companies/:companyId/products/:productId",
        name: "CompanyProductDetails",
        components: { default: CompanyProductDetails },
        props: true,
      },
      {
        path: "/companies/:companyId/products/:productId/qr-batches/request",
        name: "RequestCompanyProductQRBatch",
        components: { default: RequestCompanyProductQRBatch },
        props: true,
      },
      {
        path: "/companies/:companyId/products/:productId/qr-batches/:qrBatchId",
        name: "CompanyProductQRBatchDetails",
        components: { default: CompanyProductQRBatchDetails },
        props: true,
      },
      {
        path: "/companies/:companyId/users/new",
        name: "NewCompanyUser",
        components: { default: CreateCompanyUser },
        props: true,
      },
      {
        path: "/product-key-batches/requested",
        name: "Requested Product Key Batches",
        components: { default: RequestedProductKeyBatches },
      },
      {
        path: "/product-key-batches/approved",
        name: "Approved Product Key Batches",
        components: { default: ApprovedProductKeyBatches },
      },
    ],
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  linkActiveClass: "active",
  routes,
});

export default router;

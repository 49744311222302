const errorMessagesMap = new Map();

errorMessagesMap.set(
  "USERNAME_EXISTS",
  "This username already exists in our system!"
);
errorMessagesMap.set(
  "EMAIL_EXISTS",
  "This email already exists in our system!"
);
errorMessagesMap.set(
  "PHONE_NUMBER_EXISTS",
  "This phone number already exists in our system!"
);

export { errorMessagesMap };

<template>
  <div>
    <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8">
      <!-- Card stats -->
      <div class="row">
        <div class="col-xl-4 col-lg-6">
          <stats-card
            title="Total companies"
            type="gradient-red"
            :sub-title="this.numberOfCompanies"
            icon="ni ni-active-40"
            class="mb-4 mb-xl-0"
          >
            <template v-slot:footer>
              <span class="text-success mr-2"
                ><i class="fa fa-arrow-up"></i> 3.48%</span
              >
              <span class="text-nowrap">Since last month</span>
            </template>
          </stats-card>
        </div>
        <div class="col-xl-4 col-lg-6">
          <stats-card
            title="Approved Product Key Batches"
            type="gradient-orange"
            :sub-title="this.numberOfApprovedProductKeyBatches"
            icon="ni ni-chart-pie-35"
            class="mb-4 mb-xl-0"
          >
            <template v-slot:footer>
              <span class="text-success mr-2"
                ><i class="fa fa-arrow-up"></i> 12.18%</span
              >
              <span class="text-nowrap">Since last month</span>
            </template>
          </stats-card>
        </div>
        <div class="col-xl-4 col-lg-6">
          <stats-card
            title="Requested Product Key Batches"
            type="gradient-green"
            :sub-title="this.numberOfRequestedProductKeyBatches"
            icon="ni ni-money-coins"
            class="mb-4 mb-xl-0"
          >
            <template v-slot:footer>
              <span class="text-danger mr-2"
                ><i class="fa fa-arrow-down"></i> 5.72%</span
              >
              <span class="text-nowrap">Since last month</span>
            </template>
          </stats-card>
        </div>
      </div>
    </base-header>

    <div class="container-fluid mt--7">
      <div class="row">
        <div class="col">
          <companies-table
            :loading="loading"
            title="Companies"
            :companies="companies"
          ></companies-table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import CompaniesTable from "./Tables/CompaniesTable";

export default {
  name: "companies",
  data() {
    return {
      loading: false,
    };
  },
  components: {
    CompaniesTable,
  },
  computed: {
    companies() {
      return this.$store.state.admin.companies;
    },
    numberOfCompanies() {
      return this.$store.state.admin.companies.length.toString();
    },
    approvedProductKeyBatches() {
      return this.$store.state.admin.approvedProductKeyBatches;
    },
    numberOfApprovedProductKeyBatches() {
      return this.$store.state.admin.approvedProductKeyBatches.length.toString();
    },
    requestedProductKeyBatches() {
      return this.$store.state.admin.requestedProductKeyBatches;
    },
    numberOfRequestedProductKeyBatches() {
      return this.$store.state.admin.requestedProductKeyBatches.length.toString();
    },
  },
  mounted() {
    this.loading = true;
    // this.$store.dispatch("getCompanies").finally(() => {
    //   this.loading = false;
    // });
    // this.$store.dispatch("getApprovedProductKeyBatches");
    // this.$store.dispatch("getRequestedProductKeyBatches");

    Promise.all([
      this.$store.dispatch("getCompanies"),
      this.$store.dispatch("getApprovedProductKeyBatches"),
      this.$store.dispatch("getRequestedProductKeyBatches"),
    ]).finally(() => {
      this.loading = false;
    });
  },
};
</script>
<style></style>

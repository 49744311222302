const SET_COMPANIES = "SET_COMPANIES";
const ADD_NEW_COMPANY = "ADD_NEW_COMPANY";
const SET_COMPANY_PRODUCTS = "SET_COMPANY_PRODUCTS";
const SET_COMPANY_USERS = "SET_COMPANY_USERS";
const ADD_NEW_COMPANY_PRODUCT = "ADD_NEW_COMPANY_PRODUCT";
const ADD_NEW_COMPANY_USER = "ADD_NEW_COMPANY_USER";
const ADD_NEW_COMPANY_USER_EXCEPTION = "ADD_NEW_COMPANY_USER_EXCEPTION";
const RESET_ADD_NEW_COMPANY_USER_EXCEPTION =
  "RESET_ADD_NEW_COMPANY_USER_EXCEPTION";
const SET_COMPANY_PRODUCT_QR_BATCHES = "SET_COMPANY_PRODUCT_QR_BATCHES";
const ADD_NEW_COMPANY_PRODUCT_QR_BATCH = "ADD_NEW_COMPANY_PRODUCT_QR_BATCH";
const UPDATE_REFRESH_COMPANY_PRODUCT_QR_BATCHES =
  "UPDATE_REFRESH_COMPANY_PRODUCT_QR_BATCHES";
const SET_COMPANY_PRODUCT_QR_BATCH_KEYS = "SET_COMPANY_PRODUCT_QR_BATCH_KEYS";

// Admin Endpoints //
const SET_APPROVED_PRODUCT_KEY_BATCHES = "SET_APPROVED_PRODUCT_KEY_BATCHES";
const SET_REQUESTED_PRODUCT_KEY_BATCHES = "SET_REQUESTED_PRODUCT_KEY_BATCHES";

export {
  SET_COMPANIES,
  ADD_NEW_COMPANY,
  SET_COMPANY_PRODUCTS,
  SET_COMPANY_USERS,
  ADD_NEW_COMPANY_PRODUCT,
  ADD_NEW_COMPANY_USER,
  ADD_NEW_COMPANY_USER_EXCEPTION,
  RESET_ADD_NEW_COMPANY_USER_EXCEPTION,
  SET_COMPANY_PRODUCT_QR_BATCHES,
  ADD_NEW_COMPANY_PRODUCT_QR_BATCH,
  UPDATE_REFRESH_COMPANY_PRODUCT_QR_BATCHES,
  SET_COMPANY_PRODUCT_QR_BATCH_KEYS,
  SET_APPROVED_PRODUCT_KEY_BATCHES,
  SET_REQUESTED_PRODUCT_KEY_BATCHES,
};

<template>
  <div class="wrapper" :class="{ 'nav-open': $sidebar.showSidebar }">
    <side-bar
      :background-color="sidebarBackground"
      short-title="veriKod"
      title="veriKod"
    >
      <template v-slot:links>
        <!--        <sidebar-item-->
        <!--          :link="{-->
        <!--            name: 'Dashboard',-->
        <!--            icon: 'ni ni-tv-2 text-primary',-->
        <!--            path: '/dashboard',-->
        <!--          }"-->
        <!--        />-->
        <!--        <sidebar-item-->
        <!--          :link="{-->
        <!--            name: 'Maps',-->
        <!--            icon: 'ni ni-pin-3 text-orange',-->
        <!--            path: '/maps',-->
        <!--          }"-->
        <!--        />-->
        <!--        <sidebar-item-->
        <!--          :link="{-->
        <!--            name: 'User Profile',-->
        <!--            icon: 'ni ni-single-02 text-yellow',-->
        <!--            path: '/profile',-->
        <!--          }"-->
        <!--        />-->
        <sidebar-item
          :link="{
            name: 'Companies',
            icon: 'ni ni-shop text-red',
            path: '/companies',
          }"
        />
        <sidebar-item
          :link="{
            name: 'Requests',
            icon: 'ni ni-archive-2 text-red',
            path: '/product-key-batches/requested',
          }"
        />
        <sidebar-item
          :link="{
            name: 'Active Batches',
            icon: 'ni ni-delivery-fast text-red',
            path: '/product-key-batches/approved',
          }"
        />
        <sidebar-item
          :link="{
            name: 'Icons',
            icon: 'ni ni-planet text-blue',
            path: '/icons',
          }"
        />
        <!--        <sidebar-item-->
        <!--          :link="{-->
        <!--            name: 'Login',-->
        <!--            icon: 'ni ni-key-25 text-info',-->
        <!--            path: '/login',-->
        <!--          }"-->
        <!--        />-->
      </template>
    </side-bar>
    <div class="main-content" :data="sidebarBackground">
      <dashboard-navbar></dashboard-navbar>

      <div class="main-content__inner" @click="toggleSidebar">
        <!-- your content here -->
        <router-view></router-view>
        <content-footer v-if="!$route.meta.hideFooter"></content-footer>
      </div>
    </div>
  </div>
</template>
<script>
import ContentFooter from "./ContentFooter.vue";
import DashboardNavbar from "./DashboardNavbar.vue";

export default {
  components: {
    DashboardNavbar,
    ContentFooter,
  },
  data() {
    return {
      sidebarBackground: "vue", //vue|blue|orange|green|red|primary
    };
  },
  methods: {
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.main-content__inner {
  height: 100vh;
  margin: 0;
  display: flex;
  flex-direction: column;
  > div {
    padding-bottom: 50px;
  }
  footer {
    margin-top: auto !important;
  }
}
</style>

<template>
  <div>
    <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8">
    </base-header>

    <div class="container-fluid mt--7 col-lg-5 col-md-7">
      <div class="card bg-secondary shadow border-0">
        <div class="card-header bg-transparent pb-3">
          <div class="text-muted text-center mt-2 mb-3">
            <small>COMPANY PRODUCT DETAILS</small>
          </div>
        </div>
        <div class="card-body px-lg-5 py-lg-5">
          <Form as="el-form" :validation-schema="schema" @submit="onSubmit">
            <BasicInput
              v-model="companyProduct.name"
              :leftIcon="'el-icon-s-shop'"
              label="Product Name"
              name="name"
            />
            <BasicInput
              v-model="companyProduct.details"
              :leftIcon="'el-icon-info'"
              label="Product Details"
              name="details"
            />
            <div>
              <base-button
                style="width: 100%"
                type="primary"
                native-type="submit"
                class="my-4"
              >
                Create Company Product
              </base-button>
            </div>
          </Form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Form } from "vee-validate";
import * as yup from "yup";
import BasicInput from "../components/FormElements/Input/BasicInput";
export default {
  name: "CreateCompanyProduct",
  props: {
    companyId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      schema: {
        name: yup
          .string()
          .required()
          .max(500, "Product name can not be more than 500 characters")
          .label("Product name"),
        details: yup
          .string()
          .required("Product details can not be empty!")
          .max(500, "Product details can not be more than 500 characters")
          .label("Product details"),
      },
      companyProduct: {
        name: "",
        details: "",
      },
    };
  },
  components: {
    Form,
    BasicInput,
  },
  methods: {
    onSubmit() {
      this.$store.dispatch("createCompanyProduct", {
        companyId: this.companyId,
        name: this.companyProduct.name,
        details: this.companyProduct.details,
        createdBy: "ADMIN",
      });
      this.$router.push({
        name: "CompanyDetails",
        params: { companyId: this.companyId },
      });
    },
  },
};
</script>
<style></style>

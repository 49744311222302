<template>
  <div class="row justify-content-center">
    <div class="col-lg-5 col-md-7">
      <div class="card bg-secondary shadow border-0">
        <!--
        <div class="card-header bg-transparent pb-5">
          <div class="text-muted text-center mt-2 mb-3">
            <small>Sign in with</small>
          </div>
          <div class="btn-wrapper text-center">
            <a href="#" class="btn btn-neutral btn-icon">
              <span class="btn-inner--icon"
                ><img src="img/icons/common/github.svg"
              /></span>
              <span class="btn-inner--text">Github</span>
            </a>
            <a href="#" class="btn btn-neutral btn-icon">
              <span class="btn-inner--icon"
                ><img src="img/icons/common/google.svg"
              /></span>
              <span class="btn-inner--text">Google</span>
            </a>
          </div>
        </div>
        -->
        <div class="card-body px-lg-5 py-lg-5">
          <!--
           <div class="text-center text-muted mb-4">
            <small>Or sign in with credentials</small>
          </div>
           -->
          <form role="form">
            <el-input
              class="mb-3"
              placeholder="Username"
              prefix-icon="el-icon-user"
              v-model="model.email"
            >
            </el-input>
            <el-input
              placeholder="Password"
              prefix-icon="ni ni-lock-circle-open"
              type="password"
              v-model="model.password"
            >
            </el-input>
            <!--
             <base-checkbox class="custom-control-alternative">
              <span class="text-muted">Remember me</span>
            </base-checkbox>
             -->
            <div class="text-center">
              <base-button
                @click="login"
                type="primary"
                class="my-4 h4 mb-0 text-white text-uppercase d-none d-lg-inline-block"
              >
                Sign in
              </base-button>
            </div>
          </form>
        </div>
      </div>
      <!--
       <div class="row mt-3">
        <div class="col-6">
          <a href="#" class="text-light"><small>Forgot password?</small></a>
        </div>
        <div class="col-6 text-right">
          <router-link to="/register" class="text-light"
            ><small>Create new account</small></router-link
          >
        </div>
      </div>
       -->
    </div>
  </div>
</template>
<script>
export default {
  name: "login",
  data() {
    return {
      model: {
        email: "",
        password: "",
      },
    };
  },
  methods: {
    login() {
      if (this.model.email === "admin" && this.model.password === "admin") {
        this.$router.push({ name: "Companies" });
      }
    },
  },
};
</script>
<style>
input {
  text-indent: 10px;
}
</style>

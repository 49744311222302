import CreateCompanyUserExceptions from "@/entities/exceptions/CreateCompanyUserExceptions";
import { ApproveCompanyProductKeyBatchProxy } from "@/proxies/ApproveCompanyProductKeyBatchProxy";
import { CreateCompanyProductProxy } from "@/proxies/CreateCompanyProductProxy";
import { CreateCompanyProxy } from "@/proxies/CreateCompanyProxy";
import { CreateCompanyUserProxy } from "@/proxies/CreateCompanyUserProxy";
import { GetCompaniesProxy } from "@/proxies/GetCompaniesProxy";
import { GetCompanyProductKeyBatchesProxy } from "@/proxies/GetCompanyProductKeyBatchesProxy";
import { GetCompanyProductQRBatchKeysProxy } from "@/proxies/GetCompanyProductQRBatchKeysProxy";
import { GetCompanyProductsProxy } from "@/proxies/GetCompanyProductsProxy";
import { GetCompanyUsersProxy } from "@/proxies/GetCompanyUsersProxy";
import { GetApprovedProductKeyBatchesProxy } from "@/proxies/productKeyBatches/GetApprovedProductKeyBatchesProxy";
import { GetRequestedProductKeyBatchesProxy } from "@/proxies/productKeyBatches/GetRequestedProductKeyBatchesProxy";
import { RequestCompanyProductQRBatchProxy } from "@/proxies/RequestCompanyProductQRBatchProxy";

export default {
  getCompanies({ commit }) {
    return new Promise((resolve, reject) => {
      new GetCompaniesProxy()
        .all()
        .then((response) => {
          commit("SET_COMPANIES", response);
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  createCompany({ commit }, payload) {
    return new Promise((resolve, reject) => {
      new CreateCompanyProxy()
        .create({ item: payload })
        .then((response) => {
          commit("ADD_NEW_COMPANY", response);
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  getCompanyProducts({ commit }, companyId) {
    return new Promise((resolve, reject) => {
      new GetCompanyProductsProxy({ id: companyId })
        .all()
        .then((response) => {
          commit("SET_COMPANY_PRODUCTS", response);
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  getCompanyUsers({ commit }, companyId) {
    return new Promise((resolve, reject) => {
      new GetCompanyUsersProxy({ id: companyId })
        .all()
        .then((response) => {
          commit("SET_COMPANY_USERS", response);
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  createCompanyProduct({ commit }, payload) {
    return new Promise((resolve, reject) => {
      new CreateCompanyProductProxy({ id: payload.id })
        .create({ item: payload })
        .then((response) => {
          commit("ADD_NEW_COMPANY_PRODUCT", response);
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  createCompanyUser({ commit }, payload) {
    return new Promise((resolve, reject) => {
      new CreateCompanyUserProxy()
        .create({ item: payload })
        .then((response) => {
          commit("ADD_NEW_COMPANY_USER", response.object);
          resolve(response);
        })
        .catch((err) => {
          commit(
            "ADD_NEW_COMPANY_USER_EXCEPTION",
            CreateCompanyUserExceptions.getCreateCompanyUserErrorMessage(
              err.message
            )
          );
          reject(err);
        });
    });
  },
  resetAddNewCompanyUserException({ commit }) {
    commit("RESET_ADD_NEW_COMPANY_USER_EXCEPTION");
  },
  getCompanyProductKeyBatches({ commit }, payload) {
    return new Promise((resolve, reject) => {
      new GetCompanyProductKeyBatchesProxy({ item: payload })
        .all()
        .then((response) => {
          commit("SET_COMPANY_PRODUCT_QR_BATCHES", response);
          commit("UPDATE_REFRESH_COMPANY_PRODUCT_QR_BATCHES", false);
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  requestCompanyProductQRBatch({ commit }, payload) {
    return new Promise((resolve, reject) => {
      new RequestCompanyProductQRBatchProxy({ item: payload })
        .create({ item: payload })
        .then((response) => {
          commit("UPDATE_REFRESH_COMPANY_PRODUCT_QR_BATCHES", true);
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  approveCompanyProductKeyBatch({ commit }, payload) {
    return new Promise((resolve, reject) => {
      new ApproveCompanyProductKeyBatchProxy({ item: payload })
        .create({ item: payload })
        .then((response) => {
          commit("UPDATE_REFRESH_COMPANY_PRODUCT_QR_BATCHES", true);
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  getCompanyProductQRBatchKeys({ commit }, payload) {
    return new Promise((resolve, reject) => {
      new GetCompanyProductQRBatchKeysProxy({ item: payload })
        .all()
        .then((response) => {
          commit("SET_COMPANY_PRODUCT_QR_BATCH_KEYS", response);
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  getApprovedProductKeyBatches({ commit }) {
    return new Promise((resolve, reject) => {
      new GetApprovedProductKeyBatchesProxy()
        .all()
        .then((response) => {
          commit("SET_APPROVED_PRODUCT_KEY_BATCHES", response);
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  getRequestedProductKeyBatches({ commit }) {
    return new Promise((resolve, reject) => {
      new GetRequestedProductKeyBatchesProxy()
        .all()
        .then((response) => {
          commit("SET_REQUESTED_PRODUCT_KEY_BATCHES", response);
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};

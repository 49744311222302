<template>
  <div class="card shadow" :class="type === 'dark' ? 'bg-default' : ''">
    <div
      class="card-header border-0"
      :class="type === 'dark' ? 'bg-transparent' : ''"
    >
      <div class="row align-items-center">
        <div class="col">
          <h3 class="mb-0" :class="type === 'dark' ? 'text-white' : ''">
            {{ title }}
          </h3>
        </div>
        <div class="col text-right" @click="this.requestQRBatch">
          <base-button type="primary" size="sm">Request QR Batch</base-button>
        </div>
      </div>
    </div>

    <div class="table-responsive">
      <el-table
        empty-text="There is no batch yet"
        v-loading="loading"
        style="width: 100%"
        class="table align-items-center table-flush"
        :class="type === 'dark' ? 'table-dark' : ''"
        :data="companyProductQRBatches"
      >
        <el-table-column sortable prop="name" label="Name" width="160">
          <template #default="scope">
            <span class="text-sm">{{ scope.row.name }}</span>
          </template>
        </el-table-column>
        <el-table-column sortable prop="status" label="Status">
          <template #default="scope">
            {{ scope.row.status }}
          </template>
        </el-table-column>
        <el-table-column sortable prop="count" label="Total">
          <template #default="scope">
            {{ scope.row.count }}
          </template>
        </el-table-column>
        <el-table-column sortable prop="used" label="Used">
          <template #default="scope">
            {{ scope.row.used }}
          </template>
        </el-table-column>
        <el-table-column sortable prop="points" label="Points">
          <template #default="scope">
            {{ scope.row.points }}
          </template>
        </el-table-column>
        <el-table-column width="240" label="Additional Information">
          <template #default="scope">
            {{ scope.row.additionalInformation }}
          </template>
        </el-table-column>
        <el-table-column>
          <template #default="scope">
            <el-popover placement="top-start" :width="240" trigger="click">
              <a
                class="dropdown-item hover"
                @click="goToProductQRBatchDetails(scope.row)"
              >
                Go to QR Batch details
              </a>
              <a
                v-if="scope.row.status !== 'APPROVED'"
                class="dropdown-item hover"
                @click="approve(scope.row)"
                >Approve</a
              >
              <template #reference>
                <i class="fas fa-ellipsis-v hover"></i>
              </template>
            </el-popover>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>
<script>
export default {
  name: "company-products-table",
  computed: {
    companyProductQRBatches() {
      return this.$store.state.admin.companyProductQRBatches;
    },
    refreshCompanyProductQRBatches() {
      return this.$store.state.admin.refreshCompanyProductQRBatches;
    },
  },
  props: {
    type: String,
    title: String,
    companyId: {
      type: String,
      required: true,
    },
    productId: {
      type: String,
      required: true,
    },
  },
  mounted() {
    if (this.companyId === undefined || this.companyId === null) {
      this.$router.back();
    } else {
      this.getCompanyProductKeyBatches();
    }
  },
  data() {
    return {
      productQRBatches: [],
      loading: false,
    };
  },
  methods: {
    getCompanyProductKeyBatches() {
      this.loading = true;
      this.$store
        .dispatch("getCompanyProductKeyBatches", {
          companyId: this.companyId,
          productId: this.productId,
        })
        .then((response) => {
          if (response) {
            this.loading = false;
          }
        });
    },
    requestQRBatch() {
      this.$router.push({
        name: "RequestCompanyProductQRBatch",
        params: { companyId: this.companyId, productId: this.productId },
      });
    },
    goToProductQRBatchDetails(qrBatch) {
      if (qrBatch.status === "APPROVED") {
        this.$router.push({
          name: "CompanyProductQRBatchDetails",
          params: {
            companyId: this.companyId,
            productId: this.productId,
            qrBatchId: qrBatch.id,
          },
        });
      } else {
        this.$notify.error({
          message: "Batch is not approved yet",
          position: "bottom-right",
        });
      }
    },
    approve(productKeyBatch) {
      this.$store.dispatch("approveCompanyProductKeyBatch", {
        companyId: this.companyId,
        productId: this.productId,
        qrBatchId: productKeyBatch.id,
      });
    },
  },
  watch: {
    refreshCompanyProductQRBatches(newValue) {
      if (newValue === true) {
        this.getCompanyProductKeyBatches();
      }
    },
  },
};
</script>
<style></style>

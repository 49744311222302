import { BaseProxy } from "./BaseProxy";

export class GetCompanyUsersProxy extends BaseProxy {
  constructor({ id, parameters = {} } = {}) {
    super({
      endpoint: `companies/${id}/users`,
      parameters,
    });
  }
}

<template>
  <div class="card shadow" :class="type === 'dark' ? 'bg-default' : ''">
    <div
      class="card-header border-0"
      :class="type === 'dark' ? 'bg-transparent' : ''"
    >
      <div class="row align-items-center">
        <div class="col">
          <h3 class="mb-0" :class="type === 'dark' ? 'text-white' : ''">
            {{ title }}
          </h3>
        </div>
        <div class="col text-right" @click="this.addNewCompany">
          <base-button type="primary" size="sm">Add new company</base-button>
        </div>
      </div>
    </div>

    <div class="table-responsive">
      <el-table
        v-loading="loading"
        style="width: 100%"
        class="table align-items-center table-flush"
        :data="companies"
      >
        <el-table-column label="Name" prop="name" sortable width="240">
          <template #default="scope">
            <span class="text-sm">{{ scope.row.name }}</span>
          </template>
        </el-table-column>
        <el-table-column label="Address" prop="address" sortable width="240">
          <template #default="scope">
            {{ scope.row.address }}
          </template>
        </el-table-column>
        <el-table-column
          label="Phone Number"
          prop="phoneNumber"
          sortable
          width="180"
        >
          <template #default="scope">
            {{ scope.row.phoneNumber }}
          </template>
        </el-table-column>
        <el-table-column label="Email" prop="email" sortable width="180">
          <template #default="scope">
            {{ scope.row.email }}
          </template>
        </el-table-column>
        <el-table-column label="Status" prop="status" sortable width="180">
          <template #default="scope">
            {{ scope.row.status }}
          </template>
        </el-table-column>
        <el-table-column
          label="Number Of Users"
          prop="numberOfUsers"
          sortable
          width="180"
        >
          <template #default="scope">
            {{ scope.row.numberOfUsers }}
          </template>
        </el-table-column>
        <el-table-column>
          <template #default="scope">
            <div class="text-right pr-4">
              <el-popover placement="top-start" :width="200" trigger="click">
                <a
                  class="dropdown-item hover"
                  @click="goToCompanyDetails(scope.row)"
                >
                  Go to company details
                </a>
                <a class="dropdown-item" href="#">
                  {{ scope.row.active ? "Deactivate" : "Activate" }}
                </a>
                <template #reference>
                  <i class="fas fa-ellipsis-v hover"></i>
                </template>
              </el-popover>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>
<script>
export default {
  name: "companies-table",
  props: {
    type: {
      type: String,
    },
    loading: {
      type: Boolean,
    },
    title: String,
    companies: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {};
  },
  methods: {
    addNewCompany() {
      this.$router.push({ name: "NewCompany" });
    },
    goToCompanyDetails(company) {
      this.$router.push({
        name: "CompanyDetails",
        params: { companyId: company.id },
      });
    },
  },
};
</script>
<style></style>

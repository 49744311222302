<template>
  <div class="card shadow" :class="type === 'dark' ? 'bg-default' : ''">
    <div
      class="card-header border-0"
      :class="type === 'dark' ? 'bg-transparent' : ''"
    >
      <div class="row align-items-center">
        <div class="col">
          <h3 class="mb-0" :class="type === 'dark' ? 'text-white' : ''">
            {{ title }}
          </h3>
        </div>
        <div class="col text-right" @click="this.addNewProduct">
          <base-button type="primary" size="sm">Add new product</base-button>
        </div>
      </div>
    </div>

    <div class="table-responsive">
      <el-table
        empty-text="There is no product yet"
        v-loading="loading"
        style="width: 100%"
        class="table align-items-center table-flush"
        :class="type === 'dark' ? 'table-dark' : ''"
        :data="companyProducts"
      >
        <el-table-column sortable prop="name" label="Name">
          <template #default="scope">
            <span class="text-sm">{{ scope.row.name }}</span>
          </template>
        </el-table-column>
        <el-table-column sortable prop="details" label="Details">
          <template #default="scope">
            {{ scope.row.details }}
          </template>
        </el-table-column>
        <el-table-column sortable prop="createdBy" label="Created By">
          <template #default="scope">
            {{ scope.row.createdBy }}
          </template>
        </el-table-column>
        <el-table-column sortable prop="status" label="Status">
          <template #default="scope">
            {{ scope.row.status }}
          </template>
        </el-table-column>
        <el-table-column>
          <template #default="scope">
            <el-popover placement="top-start" :width="240" trigger="click">
              <a
                class="dropdown-item hover text-right"
                @click="goToProductDetails(scope.row)"
              >
                See product details
              </a>
              <template #reference>
                <i class="fas fa-ellipsis-v hover"></i>
              </template>
            </el-popover>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>
<script>
export default {
  name: "company-products-table",
  data() {
    return {
      loading: false,
    };
  },
  props: {
    type: String,
    title: String,
    companyId: {
      type: String,
      required: true,
    },
  },
  mounted() {
    if (this.companyId === undefined || this.companyId === null) {
      this.$router.back();
    } else {
      this.loading = true;
      this.$store
        .dispatch("getCompanyProducts", this.companyId)
        .then((response) => {
          if (response) {
            this.loading = false;
          }
        });
    }
  },
  computed: {
    companyProducts() {
      return this.$store.state.admin.companyProducts;
    },
  },
  methods: {
    addNewProduct() {
      this.$router.push({
        name: "NewCompanyProduct",
        params: { companyId: this.companyId },
      });
    },
    goToProductDetails(companyProduct) {
      this.$router.push({
        name: "CompanyProductDetails",
        params: {
          companyId: companyProduct.companyId,
          productId: companyProduct.id,
        },
      });
    },
  },
};
</script>
<style></style>

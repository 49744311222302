import { BaseProxy } from "../BaseProxy";

export class GetApprovedProductKeyBatchesProxy extends BaseProxy {
  constructor({ parameters = {} } = {}) {
    super({
      endpoint: `admin/product-key-batches/approved`,
      parameters,
    });
  }
}

import * as types from "./mutation-types";

export default {
  [types.SET_COMPANIES](state, payload) {
    state.companies = payload;
  },
  [types.ADD_NEW_COMPANY](state, payload) {
    state.companies.push(payload);
  },
  [types.SET_COMPANY_PRODUCTS](state, payload) {
    state.companyProducts = payload;
  },
  [types.ADD_NEW_COMPANY_PRODUCT](state, payload) {
    state.companyProducts.push(payload);
  },
  [types.SET_COMPANY_USERS](state, payload) {
    state.companyUsers = payload;
  },
  [types.ADD_NEW_COMPANY_USER](state, payload) {
    state.companyUsers.push(payload);
  },
  [types.ADD_NEW_COMPANY_USER_EXCEPTION](state, exceptionMessage) {
    state.createCompanyUserException = exceptionMessage;
  },
  [types.RESET_ADD_NEW_COMPANY_USER_EXCEPTION](state) {
    state.createCompanyUserException = null;
  },
  [types.SET_COMPANY_PRODUCT_QR_BATCHES](state, payload) {
    state.companyProductQRBatches = payload;
  },
  [types.UPDATE_REFRESH_COMPANY_PRODUCT_QR_BATCHES](state, payload) {
    state.refreshCompanyProductQRBatches = payload;
  },
  [types.SET_COMPANY_PRODUCT_QR_BATCH_KEYS](state, payload) {
    state.companyProductQRBatchKeys = payload;
  },
  [types.SET_APPROVED_PRODUCT_KEY_BATCHES](state, approvedProductKeyBatches) {
    state.approvedProductKeyBatches = approvedProductKeyBatches;
  },
  [types.SET_REQUESTED_PRODUCT_KEY_BATCHES](state, requestedProductKeyBatches) {
    state.requestedProductKeyBatches = requestedProductKeyBatches;
  },
};

export default {
  getCreateCompanyUserErrorMessage(message) {
    if (message === "EMAIL_EXISTS") {
      return `This email already exists in our database`;
    } else if (message === "USERNAME_EXISTS") {
      return `This username already exists in our database`;
    } else if (message === "PHONE_NUMBER_EXISTS") {
      return `This phone number already exists in our database`;
    }
  },
};

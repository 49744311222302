import { BaseProxy } from "./BaseProxy";

export class CreateCompanyProductProxy extends BaseProxy {
  constructor({ id, parameters = {} } = {}) {
    super({
      endpoint: `companies/${id}/products`,
      parameters,
    });
  }
}

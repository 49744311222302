<template>
  <div class="card shadow" :class="type === 'dark' ? 'bg-default' : ''">
    <div
      class="card-header border-0"
      :class="type === 'dark' ? 'bg-transparent' : ''"
    >
      <div class="row align-items-center">
        <div class="col">
          <h3 class="mb-0" :class="type === 'dark' ? 'text-white' : ''">
            {{ title }}
          </h3>
        </div>
      </div>
    </div>

    <div class="table-responsive">
      <el-table
        v-loading="loading"
        style="width: 100%"
        :class="type === 'dark' ? 'table-dark' : ''"
        class="table align-items-center table-flush"
        :data="companyProductQRBatchKeys"
      >
        <el-table-column label="Key">
          <template #default="scope">
            <span class="text-sm">{{ scope.row.key }}</span>
          </template>
        </el-table-column>
        <el-table-column label="QR Batch Id" width="220">
          <template #default="scope">
            {{ scope.row.productKeyBatchId }}
          </template>
        </el-table-column>
        <el-table-column label="Count" width="110">
          <template #default="scope">
            {{ scope.row.count }}
          </template>
        </el-table-column>
        <el-table-column label="QR Code 1" width="280">
          <template #default="scope">
            <qr-code-vue :value="scope.row.key" :size="200" level="L" />
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>
<script>
import QrcodeVue from "qrcode.vue";

export default {
  name: "company-products-table",
  components: {
    "qr-code-vue": QrcodeVue,
  },
  computed: {
    companyProductQRBatchKeys() {
      return this.$store.state.admin.companyProductQRBatchKeys;
    },
  },
  props: {
    type: String,
    title: String,
    companyId: {
      type: String,
      required: true,
    },
    productId: {
      type: String,
      required: true,
    },
    qrBatchId: {
      type: String,
      required: true,
    },
  },
  mounted() {
    this.getCompanyProductQRBatchKeys();
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    getCompanyProductQRBatchKeys() {
      this.loading = true;
      this.$store
        .dispatch("getCompanyProductQRBatchKeys", {
          companyId: this.companyId,
          productId: this.productId,
          qrBatchId: this.qrBatchId,
        })
        .then((response) => {
          if (response) {
            this.loading = false;
          }
        });
    },
  },
};
</script>
<style></style>

<template>
  <div>
    <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8">
    </base-header>

    <div class="container-fluid mt--7 col-lg-5 col-md-7">
      <div class="card bg-secondary shadow border-0">
        <div class="card-header bg-transparent pb-3">
          <div class="text-muted text-center mt-2 mb-3">
            <small>REQUEST PRODUCT QR BATCH</small>
          </div>
        </div>
        <div class="card-body px-lg-5 py-lg-5">
          <form role="form">
            <base-input
              formClasses="input-group-alternative"
              placeholder="Name"
              addon-left-icon="ni ni-hat-3"
              :value="companyProductQRBatch.name"
              @input="(e) => (companyProductQRBatch.name = e.target.value)"
              focused
            />

            <base-input
              formClasses="input-group-alternative"
              placeholder="Name"
              addon-left-icon="ni ni-hat-3"
              :value="companyProductQRBatch.count"
              @input="(e) => (companyProductQRBatch.count = e.target.value)"
              focused
            />

            <base-input
              formClasses="input-group-alternative"
              placeholder="Additional Information"
              addon-left-icon="ni ni-hat-3"
              :value="companyProductQRBatch.additionalInformation"
              @input="
                (e) =>
                  (companyProductQRBatch.additionalInformation = e.target.value)
              "
              focused
            />

            <div class="text-center" @click="this.requestCompanyProductQRBatch">
              <base-button type="primary" class="my-4"
                >Request product QR batch
              </base-button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "CreateCompanyProduct",
  props: {
    companyId: {
      type: String,
      required: true,
    },
    productId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      companyProductQRBatch: {
        name: "",
        count: 0,
        additionalInformation: "",
      },
    };
  },
  methods: {
    requestCompanyProductQRBatch() {
      this.$store.dispatch("requestCompanyProductQRBatch", {
        companyId: this.companyId,
        productId: this.productId,
        name: this.companyProductQRBatch.name,
        count: this.companyProductQRBatch.count,
        additionalInformation: this.companyProductQRBatch.additionalInformation,
      });
      this.$router.push({
        name: "CompanyProductDetails",
        params: {
          companyId: this.companyId,
          productId: this.productId,
          additionalInformation: this.additionalInformation,
        },
      });
    },
  },
};
</script>
<style></style>

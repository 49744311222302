<template>
  <el-form-item :error="errorMessage">
    <div class="el-input el-input-group el-input-group--append">
      <div v-if="leftIcon" class="el-input-group__prepend">
        <i :class="leftIcon"></i>
      </div>
      <el-select v-model="value" :placeholder="label" v-bind="$attrs">
        <el-option
          v-for="item in options"
          :key="item.value"
          :label="item.label"
          :value="item.value"
          :placeholder="label"
          v-model="value"
          v-bind="$attrs"
        >
        </el-option>
      </el-select>
      <div v-if="rightIcon" class="el-input-group__append">
        <i :class="rightIcon"></i>
      </div>
    </div>
  </el-form-item>
</template>

<script>
import { useField } from "vee-validate";

export default {
  name: "BasicSelect",
  inheritAttrs: false,
  props: {
    name: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    clearable: {
      type: Boolean,
      default: true,
    },
    options: {
      type: Array,
    },
    leftIcon: {
      type: String,
    },
    rightIcon: {
      type: String,
    },
  },
  setup(props) {
    const { value, errorMessage } = useField(props.name);

    return {
      value,
      errorMessage,
    };
  },
};
</script>

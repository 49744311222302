<template>
  <div>
    <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8">
    </base-header>
    <div class="container-fluid mt--7 col-lg-6 col-md-7">
      <div class="card bg-secondary shadow border-0">
        <div class="card-header bg-transparent pb-3">
          <div class="text-muted text-center mt-2 mb-3">
            <strong>COMPANY DETAILS</strong>
          </div>
        </div>
        <!--
         <el-tooltip class="history-back-btn" content="Go Back" placement="top">
          <el-button @click="goBack">
            <i class="el-icon-close" />
          </el-button>
        </el-tooltip>
         -->
        <div class="card-body px-lg-5 py-lg-5">
          <Form as="el-form" :validation-schema="schema" @submit="onSubmit">
            <BasicInput
              v-model="company.name"
              :leftIcon="'el-icon-user-solid'"
              label="Company Name"
              name="companyName"
            />
            <BasicInput
              v-model="company.address"
              :leftIcon="'ni ni-square-pin'"
              label="Company Address"
              name="companyAddress"
            />
            <BasicInput
              v-model="company.phoneNumber"
              :leftIcon="'el-icon-phone'"
              label="Company Phone Number"
              name="companyPhoneNumber"
            />
            <BasicInput
              v-model="company.email"
              :leftIcon="'el-icon-message'"
              label="Company Email Address"
              name="companyEmail"
            />
            <BasicInput
              v-model="company.information"
              :leftIcon="'el-icon-info'"
              label="Company Information"
              name="companyInformation"
            />
            <div class="card-header bg-transparent pb-3">
              <div class="text-muted text-center mt-2 mb-3">
                <strong>COMPANY MAIN CONTACT</strong>
              </div>
            </div>
            <BasicInput
              v-model="company.mainContact.firstName"
              :leftIcon="'el-icon-user-solid'"
              label="First Name"
              name="mainContactFirstName"
            />
            <BasicInput
              v-model="company.mainContact.lastName"
              :leftIcon="'el-icon-user-solid'"
              label="Last Name"
              name="mainContactLastName"
            />
            <BasicInput
              v-model="company.mainContact.phoneNumber"
              :leftIcon="'el-icon-phone'"
              label="Phone Number"
              name="mainContactPhoneNumber"
            />
            <BasicInput
              v-model="company.mainContact.email"
              :leftIcon="'el-icon-message'"
              label="Email"
              name="mainContactEmail"
            />
            <div>
              <base-button
                style="width: 100%"
                type="primary"
                native-type="submit"
                class="my-4"
              >
                Submit
              </base-button>
            </div>
          </Form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Form } from "vee-validate";
import * as yup from "yup";
import BasicInput from "../components/FormElements/Input/BasicInput";

export default {
  name: "CreateCompany",
  components: {
    Form,
    BasicInput,
  },
  data() {
    return {
      company: {
        name: null,
        address: null,
        phoneNumber: null,
        email: null,
        information: null,
        active: true,
        mainContact: {
          firstName: null,
          lastName: null,
          phoneNumber: null,
          email: null,
        },
      },
      schema: {
        companyName: yup.string().required().label("Company name"),
        companyAddress: yup.string().required().label("Company address"),
        companyPhoneNumber: yup
          .string()
          .matches(/^[0-9]*$/, "Please enter valid phone number")
          .required()
          .label("Company phone number"),
        companyEmail: yup
          .string()
          .required()
          .email("Please enter valid email")
          .label("Company email address"),
        companyInformation: yup.string(),
        mainContactFirstName: yup
          .string()
          .required()
          .label("Main contact first name"),
        mainContactLastName: yup
          .string()
          .required()
          .label("Main contact last name"),
        mainContactPhoneNumber: yup
          .string()
          .matches(/^[0-9]*$/, "Please enter valid phone number")
          .required()
          .label("Main contact phone number"),
        mainContactEmail: yup
          .string()
          .required()
          .email()
          .label("Main contact email address"),
      },
    };
  },
  methods: {
    onSubmit() {
      this.$store.dispatch("createCompany", {
        name: this.company.name,
        address: this.company.address,
        email: this.company.email,
        phoneNumber: this.company.phoneNumber,
        information: this.company.information,
        mainContactFirstName: this.company.mainContact.firstName,
        mainContactLastName: this.company.mainContact.lastName,
        mainContactPhoneNumber: this.company.mainContact.phoneNumber,
        mainContactEmail: this.company.mainContact.email,
      });
      this.$router.push({ name: "Companies" });
    },
  },
};
</script>
<style></style>

<template>
  <div>
    <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8">
      <!-- Card stats -->
      <div class="row">
        <div class="col-lg-12">
          <div class="card card-stats">
            <!-- Card body -->
            <div class="card-body" v-if="productDetails">
              <div class="row">
                <div class="col">
                  <h5 class="card-title text-uppercase text-muted mb-0">
                    Product Details
                  </h5>
                </div>
              </div>
              <p class="mt-3 mb-0 text-sm">
                <span class="text-success mr-2">Name:</span>
                <span class="text-nowrap">{{ productDetails.name }}</span>
              </p>
              <p class="mt-1 mb-0 text-sm">
                <span class="text-success mr-2">Additional Information:</span>
                <span class="text-nowrap">{{ productDetails.details }}</span>
              </p>
              <p class="mt-1 mb-0 text-sm">
                <span class="text-success mr-2">Status:</span>
                <span class="text-nowrap">{{ productDetails.status }}</span>
              </p>
              <p class="mt-1 mb-0 text-sm">
                <span class="text-success mr-2">Created By:</span>
                <span class="text-nowrap">{{ productDetails.createdBy }}</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--7">
      <div class="row">
        <div class="col">
          <company-product-qr-batches-table
            type="dark"
            title="QR Batches"
            :companyId="companyId"
            :productId="productId"
          ></company-product-qr-batches-table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import CompanyProductQRBatchesTable from "./Tables/CompanyProductQRBatchesTable";
import { GetCompanyProductDetails } from "@/proxies/GetCompanyProductDetails";

export default {
  name: "company-product-details",
  components: {
    "company-product-qr-batches-table": CompanyProductQRBatchesTable,
  },
  props: {
    companyId: {
      type: String,
      required: true,
    },
    productId: {
      type: String,
      required: true,
    },
  },
  created() {
    this.getCompanyProductDetail();
  },
  data() {
    return {
      productDetails: null,
    };
  },
  methods: {
    getCompanyProductDetail() {
      new GetCompanyProductDetails({
        item: {
          companyId: this.companyId,
          productId: this.productId,
        },
      })
        .all()
        .then((response) => {
          this.productDetails = response;
        });
    },
  },
};
</script>
<style></style>

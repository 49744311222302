import { Admin } from "@/utils/request";
import qs from "qs";

const ApiMap = new Map();
ApiMap.set("admin", Admin);

const datableTypes = ["post", "put", "patch"];

export class BaseProxy {
  constructor({ endpoint, api = "admin", parameters = {} }) {
    this.$http = ApiMap.get(api);
    this.endpoint = endpoint;
    this.parameters = parameters;
    this.config = {
      headers: {},
    };
  }
  submit(requestType, url, data = null) {
    return new Promise((resolve, reject) => {
      let args = [url + this.getParameterString(), this.config];
      if (datableTypes.includes(requestType)) {
        args.splice(1, 0, data);
      }
      this.$http[requestType](...args)
        .then((response) => {
          resolve(response.data);
        })
        .catch(({ response }) => {
          if (response) {
            reject(response.data);
          } else {
            reject(response);
          }
        });
    });
  }

  all() {
    return this.submit("get", `/${this.endpoint}`);
  }

  find({ id }) {
    return this.submit("get", `/${this.endpoint}/${id}`);
  }

  create({ item }) {
    return this.submit("post", `/${this.endpoint}`, item);
  }

  getParameterString() {
    const parameterStrings = qs.stringify(this.parameters, {
      encodeValuesOnly: true,
    });
    return parameterStrings.length === 0 ? "" : `?${parameterStrings}`;
  }
}

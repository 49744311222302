<template>
  <div>
    <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8">
      <!-- Card stats -->
      <!--      <div class="row">-->
      <!--        <div class="col-xl-4 col-lg-6">-->
      <!--          <stats-card-->
      <!--            title="Total companies"-->
      <!--            type="gradient-red"-->
      <!--            :sub-title="this.numberOfCompanies"-->
      <!--            icon="ni ni-active-40"-->
      <!--            class="mb-4 mb-xl-0"-->
      <!--          >-->
      <!--            <template v-slot:footer>-->
      <!--              <span class="text-success mr-2"-->
      <!--                ><i class="fa fa-arrow-up"></i> 3.48%</span-->
      <!--              >-->
      <!--              <span class="text-nowrap">Since last month</span>-->
      <!--            </template>-->
      <!--          </stats-card>-->
      <!--        </div>-->
      <!--        <div class="col-xl-4 col-lg-6">-->
      <!--          <stats-card-->
      <!--            title="Approved Product Key Batches"-->
      <!--            type="gradient-orange"-->
      <!--            :sub-title="this.numberOfApprovedProductKeyBatches"-->
      <!--            icon="ni ni-chart-pie-35"-->
      <!--            class="mb-4 mb-xl-0"-->
      <!--          >-->
      <!--            <template v-slot:footer>-->
      <!--              <span class="text-success mr-2"-->
      <!--                ><i class="fa fa-arrow-up"></i> 12.18%</span-->
      <!--              >-->
      <!--              <span class="text-nowrap">Since last month</span>-->
      <!--            </template>-->
      <!--          </stats-card>-->
      <!--        </div>-->
      <!--        <div class="col-xl-4 col-lg-6">-->
      <!--          <stats-card-->
      <!--            title="Requested Product Key Batches"-->
      <!--            type="gradient-green"-->
      <!--            :sub-title="this.numberOfRequestedProductKeyBatches"-->
      <!--            icon="ni ni-money-coins"-->
      <!--            class="mb-4 mb-xl-0"-->
      <!--          >-->
      <!--            <template v-slot:footer>-->
      <!--              <span class="text-danger mr-2"-->
      <!--                ><i class="fa fa-arrow-down"></i> 5.72%</span-->
      <!--              >-->
      <!--              <span class="text-nowrap">Since last month</span>-->
      <!--            </template>-->
      <!--          </stats-card>-->
      <!--        </div>-->
      <!--      </div>-->
    </base-header>
    <div class="container-fluid mt--7">
      <div class="row">
        <div class="col">
          <div class="card shadow" :class="type === 'dark' ? 'bg-default' : ''">
            <div
              class="card-header border-0"
              :class="type === 'dark' ? 'bg-transparent' : ''"
            >
              <div class="row align-items-center">
                <div class="col">
                  <h3 class="mb-0" :class="type === 'dark' ? 'text-white' : ''">
                    Waiting for approval
                  </h3>
                </div>
              </div>
            </div>

            <div class="table-responsive">
              <el-table
                empty-text="No requested batch"
                v-loading="loading"
                style="width: 100%"
                class="table align-items-center table-flush"
                :class="type === 'dark' ? 'table-dark' : ''"
                :data="requestedProductKeyBatches"
                :default-sort="{ prop: 'createdAt', order: 'descending' }"
              >
                <el-table-column width prop="name" sortable label="Name">
                  <template #default="scope">
                    <span class="text-sm">{{ scope.row.name }}</span>
                  </template>
                </el-table-column>
                <el-table-column label="Product">
                  <template #default="scope">
                    <span class="text-sm">{{ scope.row.product.name }}</span>
                  </template>
                </el-table-column>
                <el-table-column label="Company">
                  <template #default="scope">
                    <span class="text-sm">{{ scope.row.company.name }}</span>
                  </template>
                </el-table-column>
                <el-table-column label="Status">
                  <template #default="scope">
                    <el-tag type="success">{{ scope.row.status }}</el-tag>
                  </template>
                </el-table-column>
                <el-table-column label="Total">
                  <template #default="scope">
                    {{ scope.row.count }}
                  </template>
                </el-table-column>
                <el-table-column label="Points">
                  <template #default="scope">
                    {{ scope.row.points }}
                  </template>
                </el-table-column>
                <el-table-column label="Additional Information">
                  <template #default="scope">
                    {{ scope.row.additionalInformation }}
                  </template>
                </el-table-column>
                <el-table-column
                  prop="createdAt"
                  sortable
                  label="Requested Date & Time"
                >
                  <template #default="scope">
                    {{ moment(scope.row.createdAt) }}
                  </template>
                </el-table-column>
                <el-table-column>
                  <template #default="scope">
                    <el-popover
                      placement="top-start"
                      :width="240"
                      trigger="click"
                    >
                      <a
                        class="dropdown-item hover"
                        @click="goToProductQRBatchDetails(scope.row)"
                      >
                        Go to QR Batch details
                      </a>
                      <a
                        v-if="scope.row.status !== 'APPROVED'"
                        class="dropdown-item hover"
                        @click="approve(scope.row)"
                        >Approve</a
                      >
                      <template #reference>
                        <i class="fas fa-ellipsis-v hover"></i>
                      </template>
                    </el-popover>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import moment from "moment";

export default {
  name: "requested-product-key-batches",
  computed: {
    requestedProductKeyBatches() {
      return this.$store.state.admin.requestedProductKeyBatches;
    },
    moment() {
      return (date) => {
        return moment(date).format("MMMM Do YYYY, h:mm:ss a");
      };
    },
  },
  props: {
    type: String,
    title: String,
  },
  mounted() {
    this.refreshRequestedProductKeyBatches();
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    refreshRequestedProductKeyBatches() {
      this.loading = true;
      this.$store.dispatch("getRequestedProductKeyBatches").finally(() => {
        this.loading = false;
      });
    },
    approve(productKeyBatch) {
      this.$store
        .dispatch("approveCompanyProductKeyBatch", {
          companyId: productKeyBatch.companyId,
          productId: productKeyBatch.productId,
          qrBatchId: productKeyBatch.id,
        })
        .finally(() => {
          this.refreshRequestedProductKeyBatches();
        });
    },
  },
};
</script>
<style></style>

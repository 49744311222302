import { BaseProxy } from "./BaseProxy";

export class GetCompanyProductQRBatchKeysProxy extends BaseProxy {
  constructor({ item, parameters = {} } = {}) {
    super({
      endpoint: `companies/${item.companyId}/products/${item.productId}/key-batches/${item.qrBatchId}/keys/`,
      parameters,
    });
  }
}

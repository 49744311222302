<template>
  <div>
    <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8">
      <!-- Card stats -->
      <div class="row">
        <div class="col-lg-6">
          <div class="card card-stats">
            <!-- Card body -->
            <div class="card-body" v-if="companyDetails">
              <div class="row">
                <div class="col">
                  <h5 class="card-title text-uppercase text-muted mb-0">
                    Company Details
                  </h5>
                </div>
              </div>
              <p class="mt-3 mb-0 text-sm">
                <span class="text-success mr-2">Name:</span>
                <span>{{ companyDetails.name }}</span>
              </p>
              <p class="mt-1 mb-0 text-sm">
                <span class="text-success mr-2">Address:</span>
                <span>{{ companyDetails.address }}</span>
              </p>
              <p class="mt-1 mb-0 text-sm">
                <span class="text-success mr-2">Phone Number:</span>
                <span>{{ companyDetails.phoneNumber }}</span>
              </p>
              <p class="mt-1 mb-0 text-sm">
                <span class="text-success mr-2">Email:</span>
                <span>{{ companyDetails.email }}</span>
              </p>
              <p class="mt-1 mb-0 text-sm" v-if="companyDetails.information">
                <span class="text-success mr-2">Additional Information:</span>
                <span>{{ companyDetails.information }}</span>
              </p>
            </div>
          </div>
        </div>
        <div class="col-lg-6" v-if="companyDetails">
          <div class="card card-stats">
            <!-- Card body -->
            <div class="card-body">
              <div class="row">
                <div class="col">
                  <h5 class="card-title text-uppercase text-muted mb-0">
                    Main Contact Details
                  </h5>
                </div>
              </div>
              <p class="mt-3 mb-0 text-sm">
                <span class="text-success mr-2">Name Surname:</span>
                <span>
                  {{ companyDetails.mainContactFirstName }}
                  {{ companyDetails.mainContactLastName }}
                </span>
              </p>
              <p class="mt-3 mb-0 text-sm">
                <span class="text-success mr-2">Phone Number:</span>
                <span>{{ companyDetails.mainContactPhoneNumber }}</span>
              </p>
              <p class="mt-3 mb-0 text-sm">
                <span class="text-success mr-2">Email:</span>
                <span>{{ companyDetails.mainContactEmail }}</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </base-header>

    <div class="container-fluid mt--7">
      <div class="row">
        <div class="col">
          <company-users-table
            type="dark"
            title="Users"
            :companyId="companyId"
          ></company-users-table>
        </div>
      </div>
      <br />
      <div class="row">
        <div class="col">
          <company-products-table
            type="dark"
            title="Products"
            :companyId="companyId"
          ></company-products-table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import CompanyProductsTable from "./Tables/CompanyProductsTable";
import CompanyUsersTable from "./Tables/CompanyUsersTable";
import { GetCompanyDetails } from "@/proxies/GetCompanyDetails";

export default {
  name: "company-details",
  components: {
    CompanyProductsTable,
    CompanyUsersTable,
  },
  props: {
    companyId: {
      type: String,
      required: true,
    },
  },
  created() {
    this.getCompanyDetails();
  },
  data() {
    return {
      companyDetails: null,
    };
  },
  methods: {
    getCompanyDetails() {
      new GetCompanyDetails({ companyId: this.companyId })
        .all()
        .then((response) => {
          this.companyDetails = response;
        });
    },
  },
};
</script>
<style></style>

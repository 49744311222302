import { BaseProxy } from "../BaseProxy";

export class GetRequestedProductKeyBatchesProxy extends BaseProxy {
  constructor({ parameters = {} } = {}) {
    super({
      endpoint: `admin/product-key-batches/requested`,
      parameters,
    });
  }
}

import { BaseProxy } from "./BaseProxy";

export class GetCompanyProductQRBatchDetailsProxy extends BaseProxy {
  constructor({ item, parameters = {} } = {}) {
    super({
      endpoint: `companies/${item.companyId}/products/${item.productId}/key-batches/${item.productKeyBatchId}/`,
      parameters,
    });
  }
}
